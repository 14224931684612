import { CustomCellRendererProps } from 'ag-grid-react';

import Badge, { BadgeColors } from './ui/Badge';

interface GridCellBadgeRendererParams extends CustomCellRendererProps {
    colorMapping?: (value: string) => BadgeColors;
}

function GridCellBadge({ value, colorMapping }: GridCellBadgeRendererParams) {
    const color = colorMapping ? colorMapping(value) : BadgeColors.DEFAULT;
    return <Badge value={value} color={color}></Badge>;
}

export default GridCellBadge;
