import { CustomCellRendererProps } from 'ag-grid-react';

import Badge, { BadgeColors } from './ui/Badge';

interface GridCellBadgeRendererParams extends CustomCellRendererProps {
    colorMapping?: (value: string) => BadgeColors;
}

function GridCellBadgeList({ value, colorMapping }: GridCellBadgeRendererParams) {
    if (!value) return;
    const components = value
        .map((value: string, index: number) => {
            if (value) {
                const color = colorMapping ? colorMapping(value) : BadgeColors.DEFAULT;
                return <Badge key={value + index} value={value} color={color}></Badge>;
            } else {
                return null;
            }
        })
        .filter(Boolean);
    return components;
}

export default GridCellBadgeList;
