import { UserbackProvider, useUserback } from '@userback/react';
import React, { ReactNode, useEffect } from 'react';

import { email, firstName, lastName, workosId } from '../state/user';

interface UserbackWidgetProps {
    children?: ReactNode;
}

// This is the new self-contained component
const UserbackDataHandler: React.FC = () => {
    const { identify } = useUserback();

    useEffect(() => {
        identify(workosId.value, {
            name: `${firstName.value} ${lastName.value}`,
            email: email.value,
        });
        // Ensure this effect runs only when the user data changes
    }, [workosId.value, firstName.value, lastName.value, email.value]);

    return null; // This component does not render any JSX
};

const UserbackWidget: React.FC<UserbackWidgetProps> = ({ children }) => {
    return (
        <UserbackProvider
            token="P-TXXFgGXSdb9eaIBD6GtErptnY"
            options={{ native_screenshot: true }}
        >
            <UserbackDataHandler /> {/* Insert the self-contained component here */}
            {children}
        </UserbackProvider>
    );
};

export default UserbackWidget;
