import React, { useEffect, useState } from 'react';

import IframeContainer from '../components/IframeContainer';

function Pillar() {
    const [pillarUrl, setPillarUrl] = useState('');

    useEffect(() => {
        getPillarURL();
    }, []);

    async function getPillarURL() {
        try {
            const response = await fetch('/pillar_embed');
            if (response.ok) {
                const url = await response.text();
                setPillarUrl(url);
            } else {
                console.error('Failed to fetch the Pillar URL:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching the Pillar URL:', error);
        }
    }

    return (
        <>
            <IframeContainer
                appName="Pillar"
                src={pillarUrl || 'about:blank'}
                title="Pillar"
            ></IframeContainer>
        </>
    );
}

export default Pillar;
