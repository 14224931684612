import { computed, signal } from '@preact/signals-core';

import { AuthenticatedUser } from '../Types/User';

export const firstName = signal('');
export const lastName = signal('');
export const workosId = signal('');
export const id = signal<number>(0);
export const email = signal('');
export const profilePictureUrl = signal('');

export const user = computed<Partial<AuthenticatedUser>>(() => ({
    firstName: firstName.value,
    lastName: lastName.value,
    fullName: `${firstName.value} ${lastName.value}`,
    workosId: workosId.value,
    id: id.value,
    email: email.value,
    profilePictureUrl: profilePictureUrl.value,
}));
