export enum BadgeColors {
    DEFAULT, // gray
    ERROR, // red
    WARNING, // yellow
    INFO, // blue
    ACCEPTED, // green
    DESCOPED, // dark
}

interface IBadgeColorProperties {
    [key: number]: { backgroundColor: string; textColor: string; ringColor: string };
}

const badgeColors: IBadgeColorProperties = {
    [BadgeColors.DEFAULT]: {
        backgroundColor: 'bg-gray-50',
        textColor: 'text-gray-600',
        ringColor: 'ring-gray-500/10',
    },
    [BadgeColors.ERROR]: {
        backgroundColor: 'bg-red-50',
        textColor: 'text-red-700',
        ringColor: 'ring-red-600/10',
    },
    [BadgeColors.WARNING]: {
        backgroundColor: 'bg-yellow-50',
        textColor: 'text-yellow-800',
        ringColor: 'ring-yellow-600/20',
    },
    [BadgeColors.INFO]: {
        backgroundColor: 'bg-blue-50',
        textColor: 'text-blue-700',
        ringColor: 'ring-blue-700/10',
    },
    [BadgeColors.ACCEPTED]: {
        backgroundColor: 'bg-green-50',
        textColor: 'text-green-700',
        ringColor: 'ring-green-600/20',
    },
    [BadgeColors.DESCOPED]: {
        backgroundColor: 'bg-gray-300',
        textColor: 'text-gray-800',
        ringColor: 'ring-gray-600/20',
    },
};

interface BadgeProps {
    value: string;
    color?: BadgeColors;
}

function Badge({ value, color = BadgeColors.DEFAULT }: BadgeProps) {
    const { backgroundColor, textColor, ringColor } = badgeColors[color];
    return (
        <span
            className={`inline-flex items-center rounded-md ${backgroundColor} px-2 py-1 mr-0.5 mb-0.5 text-xs font-medium ${textColor} ring-1 ring-inset ${ringColor}`}
        >
            {value}
        </span>
    );
}

export default Badge;
