import React, { CSSProperties } from 'react';

import { currentPath } from '../state/router';

interface LinkProps {
    to: string;
    children: React.ReactNode;
    className?: string;
}

export const Link: React.FC<LinkProps> = ({ to, children, className }) => {
    const isActive = currentPath.value === to;

    const navigate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        history.pushState({}, '', to);
        currentPath.value = to; // Update the signal value
    };

    const combinedClassName = `${className || ''} ${isActive ? 'active' : ''}`.trim();

    return (
        <a href={to} onClick={navigate} className={combinedClassName}>
            {children}
        </a>
    );
};

interface RouteProps {
    path: string;
    children: React.ReactNode;
}

export const Route: React.FC<RouteProps> = ({ path, children }) => {
    const isVisible = currentPath.value === path;

    const style: CSSProperties = isVisible
        ? {}
        : {
              position: 'absolute', // Take it out of document flow
              top: -10000,
              left: -10000,
              visibility: 'hidden', // Make it invisible
          };

    return <div style={style}>{children}</div>;
};
