import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the grid
import './Worksheet.css';

import AuditGrid from '../components/AuditGrid';
import AuditSelect from '../components/AuditSelect';
import ExportXLSX from '../components/ExportXLSX';
import MicroWrapper from '../components/MicroWrapper';
import { selectedAudit } from '../state/audit';

function Worksheet() {
    const headerContent = (
        <div className="px-4 py-5 flex w-full gap-4">
            <AuditSelect selectedAudit={selectedAudit}></AuditSelect>
            {selectedAudit && (
                <p className="p-2 text-sm text-gray-600">
                    Board ID: {selectedAudit.value?.id}
                </p>
            )}
            <div className="justify-end grow flex">
                <ExportXLSX
                    selectedAudit={selectedAudit.value}
                    filename={selectedAudit.value?.title || ''}
                ></ExportXLSX>
            </div>
        </div>
    );

    const bodyContent = <AuditGrid audit={selectedAudit.value}></AuditGrid>;

    return <MicroWrapper header={headerContent} body={bodyContent} />;
}

export default Worksheet;
