import KnockSettings from '../components/KnockSettings';

const Settings = () => {
    return (
        <form className="p-6">
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10  pb-4 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Notifications
                        </h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Configure your Knock notification settings.
                        </p>
                    </div>

                    <KnockSettings></KnockSettings>
                </div>
            </div>

            {/* <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                    type="button"
                    className="text-sm font-semibold leading-6 text-gray-900"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>
            </div> */}
        </form>
    );
};

export default Settings;
