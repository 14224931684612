import RetoolContainer from '../components/RetoolContainer';

function LexiAudit() {
    return (
        <RetoolContainer
            appName="Lexi Audit"
            appUUID="feade4ba-adc0-11ee-919f-8b46db90eb5f"
        ></RetoolContainer>
    );
}

export default LexiAudit;
