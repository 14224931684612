import React, { ReactNode } from 'react';

interface MicroWrapperProps {
    header: ReactNode;
    body: ReactNode;
}

const MicroWrapper: React.FC<MicroWrapperProps> = ({ header, body }) => {
    return (
        <div className="h-[calc(100vh-2rem)] w-full">
            <div className="flex flex-col w-full h-full divide-y divide-gray-200">
                <div className="flex flex-row items-center">
                    {/* Render header section */}
                    {header}
                </div>
                <div className="flex grow">
                    {/* Render body section */}
                    {body}
                </div>
            </div>
        </div>
    );
};

export default MicroWrapper;
