import { useQuery } from '@tanstack/react-query';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function fetchFromAPI<T>(endpoint: string): Promise<T> {
    const response = await fetch(endpoint);
    const message = await response.json();
    if (!response.ok) {
        throw new Error(message.message);
    }
    return message;
}

export function getList() {
    return useQuery({
        queryKey: ['fetchFromAPI', 'utilities', 'list'],
        queryFn: () => fetchFromAPI(`/api/utilities/list`),
    });
}

export async function executeScript<T>(endpoint: string, args: object): Promise<T> {
    const params = new URLSearchParams();
    for (const [k, v] of Object.entries(args))
        Array.isArray(v)
            ? v.forEach((val) => params.append(k, val))
            : params.append(k, v);

    const url: string = `${endpoint}?${params.toString()}`;
    return await fetchFromAPI(url);
}
