import { DocumentIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { CustomCellRendererProps } from 'ag-grid-react';
import React from 'react';

import { Attachment } from '../Types/Card';

export const FILE_ICONS: Record<string, React.ReactNode> = {
    image: <PhotoIcon></PhotoIcon>,
    document: <DocumentIcon></DocumentIcon>,
};

interface GridCellAttachmentsParams extends CustomCellRendererProps {
    prop: string;
}

function GridCellAttachments({ data: row, prop }: GridCellAttachmentsParams) {
    const attachments = row[prop] as Attachment[];

    const icon = (attachmentType: string) =>
        FILE_ICONS[attachmentType] || FILE_ICONS['document'];

    const downFn = (fileUrl: string) => {
        const proxyUrl = `/api/download?url=${encodeURIComponent(fileUrl)}`;

        fetch(proxyUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = fileUrl.split('/').pop() || ''; // Extract filename
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
            });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isImageOrPdf = (attachment: any) => {
        const type = attachment.type;
        const ext = attachment.name.split('.').pop();
        if (ext === 'svg') return false; // download svg
        if (type === 'image') return true;
        if (type === 'document' && ext === 'pdf') return true;
        return false;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderLink = (attachment: any) => {
        if (isImageOrPdf(attachment)) {
            return (
                <a
                    target="_blank"
                    href={attachment.url}
                    rel="noreferrer"
                    className="flex flex-row gap-2 content-center items-center cursor-pointer"
                    download
                >
                    <span className="h-6 w-6">{icon(attachment.type)}</span>
                    <span className="w-3/4">{attachment.name}</span>
                </a>
            );
        } else {
            return (
                <button
                    className="flex flex-row gap-2 content-center items-center cursor-pointer"
                    onClick={() => downFn(attachment.url)}
                    data-url={attachment.url}
                >
                    <span className="h-6 w-6">{icon(attachment.type)}</span>
                    <span className="w-3/4">{attachment.name}</span>
                </button>
            );
        }
    };

    return (
        <div className="flex flex-col h-100 gap-2">
            {(attachments || []).map((attachment) => {
                return (
                    <div key={attachment.id} className="p-1 border-b">
                        {renderLink(attachment)}
                    </div>
                );
            })}
        </div>
    );
}

export default GridCellAttachments;
