// Required CSS import, unless you're overriding the styling
import '@knocklabs/react/dist/index.css';

import {
    KnockFeedProvider,
    KnockProvider,
    NotificationFeedPopover,
    NotificationIconButton,
} from '@knocklabs/react';
import { useRef, useState } from 'react';

import { workosId } from '../state/user';

const KnockWidget = () => {
    const [isVisible, setIsVisible] = useState(false);
    const notifButtonRef = useRef(null);
    const { VITE_KNOCK_PUBLIC_API_KEY, VITE_KNOCK_FEED_CHANNEL_ID } = import.meta.env;

    return (
        <KnockProvider apiKey={VITE_KNOCK_PUBLIC_API_KEY} userId={workosId.value}>
            <KnockFeedProvider feedId={VITE_KNOCK_FEED_CHANNEL_ID}>
                <>
                    <NotificationIconButton
                        ref={notifButtonRef}
                        onClick={() => setIsVisible(!isVisible)}
                    />
                    <NotificationFeedPopover
                        buttonRef={notifButtonRef}
                        isVisible={isVisible}
                        onClose={() => setIsVisible(false)}
                    />
                </>
            </KnockFeedProvider>
        </KnockProvider>
    );
};

export default KnockWidget;
