import './index.css';

import { effect } from '@preact/signals-react';
import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import App from './App';
import UserbackWidget from './components/UserbackWidget';
import { email, firstName, lastName, workosId } from './state/user';

const { VITE_REACT_APP_PUBLIC_POSTHOG_HOST, PROD, VITE_REACT_APP_PUBLIC_POSTHOG_KEY } =
    import.meta.env;

Sentry.init({
    dsn: 'https://f77f543e8ef7bf043c285beac849f7d4@o4507093498855424.ingest.us.sentry.io/4507094238232576',
    enabled: PROD,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        'localhost',
        /^https:\/\/dash\.pillargrc\.com/,
        /^https:\/\/dev\.dash\.pillargrc\.com/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const posthogOptions = {
    api_host: VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
};

const container = document.getElementById('root');
const root = createRoot(container!);

effect(() => {
    if (workosId.value) {
        const tagManagerArgs = {
            gtmId: 'GTM-PFTMKHRZ',
            dataLayer: {
                id: workosId.value,
                email: email.value,
                name: `${firstName.value} ${lastName.value}`,
            },
        };
        TagManager.initialize(tagManagerArgs);
    }
});

root.render(
    <React.StrictMode>
        <PostHogProvider
            apiKey={VITE_REACT_APP_PUBLIC_POSTHOG_KEY}
            options={posthogOptions}
        >
            <UserbackWidget>
                <App />
            </UserbackWidget>
        </PostHogProvider>
    </React.StrictMode>,
);
