import { REVIEW_STATUS, ReviewStatus, reviewStatusStrings } from '../Types/Review';
import { SelectBox } from './ui/Select';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export enum ReviewStatusColors {
    DEFAULT, // gray
    ERROR, // red
    ACCEPTED, // green
}

interface IReviewStatusColorProperties {
    [key: number]: { backgroundColor: string };
}

const reviewStatusColors: IReviewStatusColorProperties = {
    [ReviewStatusColors.DEFAULT]: {
        backgroundColor: 'bg-gray-200',
    },
    [ReviewStatusColors.ERROR]: {
        backgroundColor: 'bg-red-500',
    },
    [ReviewStatusColors.ACCEPTED]: {
        backgroundColor: 'bg-green-500',
    },
};

const reviewStatusColorMapping = (value: string) => {
    if (value === REVIEW_STATUS.notStarted) {
        return reviewStatusColors[ReviewStatusColors.DEFAULT];
    } else if (value === REVIEW_STATUS.approved) {
        return reviewStatusColors[ReviewStatusColors.ACCEPTED];
    } else if (value === REVIEW_STATUS.responseRequired) {
        return reviewStatusColors[ReviewStatusColors.ERROR];
    }
};

export interface ReviewStatusSelectProps {
    selected: ReviewStatus;
    setSelected: (status: ReviewStatus) => void;
}

export default function ReviewStatusSelect({
    selected,
    setSelected,
}: ReviewStatusSelectProps) {
    const handleChange = (status: ReviewStatus) => {
        setSelected(status);
    };

    const reviewStatusValues = Object.values(REVIEW_STATUS);

    return (
        <SelectBox
            onChange={handleChange}
            items={reviewStatusValues}
            selected={selected}
            getItemLabel={(status) => reviewStatusStrings[status]}
            getItemIcon={(status) => (
                <span
                    aria-label={status}
                    className={classNames(
                        reviewStatusColorMapping(status)?.backgroundColor ?? '',
                        'inline-block h-2 w-2 flex-shrink-0 rounded-full',
                    )}
                />
            )}
        ></SelectBox>
    );
}
