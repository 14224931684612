import React, { useState } from 'react';

import Utility from '../components/Utility';
import { getList } from '../services/utilitiesService';
import { UtilCommand } from '../Types/Util';

const Utilities = () => {
    const [active, setActive] = useState<string>('');
    const raw_data: unknown = getList().data;
    if (!raw_data) return <div>No data</div>;

    const data: UtilCommand[] = Object.values(raw_data);

    const commandHandler = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        command: string,
    ): void => {
        event.preventDefault();
        setActive(command);
    };

    return (
        <form className="p-6">
            <div className="space-y-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Utilities - Manual Intervention Scripts
                </h2>
                <div className="flex flex-row">
                    <div className="p-3">
                        {data.map((utilData) => {
                            return (
                                <div className="p-3" key={utilData.name}>
                                    <button
                                        onClick={(e) => commandHandler(e, utilData.name)}
                                        className={`${active === utilData.name ? 'font-bold' : 'font-normal'} text-blue-600 dark:text-blue-500 hover:underline text-left`}
                                    >
                                        {utilData.name}
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                    <div className="p-3 grow">
                        {data.map((utilData) => {
                            return (
                                <div
                                    key={utilData.name}
                                    className={active === utilData.name ? '' : 'hidden'}
                                >
                                    <Utility data={utilData} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Utilities;
