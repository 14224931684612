import Knock, {
    ChannelTypePreferences,
    PreferenceSet,
    SetPreferencesProperties,
    WorkflowPreferenceSetting,
} from '@knocklabs/client';
import { ChannelType } from '@knocklabs/types';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { id } from '../state/user';

const workflowLabels: { [key: string]: string } = {
    'card-assigned': 'Card Assigned',
    'card-status-changed': 'Card Status Changed',
    'comment-mention-notification': 'Mentioned in Comment',
    'comment-notification': 'New Comment (assignee)',
    'debug-digest': 'Pillar Daily Recap',
    'invite-client-to-assessment': 'Invite Client to Assessment',
    'invite-client-to-board': 'Invite Client to Board',
    'new-comment-board-member': 'New Comment (board member)',
};

const channelTypeLabels = {
    chat: 'Chat',
    email: 'Email',
    in_app_feed: 'Feed',
    sms: 'SMS',
    push: 'Push',
    http: 'HTTP',
};

// Type guard to check if workflow setting includes channel types
const hasChannelTypes = (
    setting: WorkflowPreferenceSetting,
): setting is { channel_types: ChannelTypePreferences } =>
    typeof setting === 'object' && setting !== null && 'channel_types' in setting;

const LoadingSpinner: React.FC = () => {
    return (
        <div className="flex justify-center items-center">
            <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-indigo-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                ></circle>
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
        </div>
    );
};

type ChannelOptionProps = {
    channel: string;
    setting: boolean;
    updatePreferences: (path: string[], value: boolean) => void;
    loading: boolean;
};

const ChannelOption: React.FC<ChannelOptionProps> = ({
    channel,
    setting,
    updatePreferences,
    loading,
}) => {
    return (
        <div className="relative flex gap-x-3">
            <div className="flex h-6 items-center">
                <input
                    id={channel}
                    name={channel}
                    type="checkbox"
                    checked={setting}
                    disabled={loading}
                    onChange={() =>
                        updatePreferences(['channel_types', channel], !setting)
                    }
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
            </div>
            <label
                htmlFor={channel}
                className="text-sm leading-6 font-medium text-gray-900"
            >
                {channelTypeLabels[channel as ChannelType]}
            </label>
            {loading && <LoadingSpinner />}
        </div>
    );
};

type WorkflowOptionProps = {
    workflowKey: string;
    workflowSetting?: WorkflowPreferenceSetting;
    updatePreferences: (path: string[], value: boolean) => void;
    loadingPreferences: Set<string>;
};

const WorkflowOption: React.FC<WorkflowOptionProps> = ({
    workflowKey,
    workflowSetting,
    updatePreferences,
    loadingPreferences,
}) => {
    if (!workflowSetting || !hasChannelTypes(workflowSetting)) {
        return null;
    }

    const workflowChannelPreferences = hasChannelTypes(workflowSetting)
        ? workflowSetting.channel_types
        : {};

    return (
        <fieldset>
            <legend className="text-sm font-semibold leading-6 text-gray-900">
                {workflowLabels[workflowKey] ?? workflowKey}
            </legend>
            <div className="">
                {Object.entries(workflowChannelPreferences).map(
                    ([channelType, preferenceSetting]) => {
                        const loadingKey = `workflows-${workflowKey}-channel_types-${channelType}`;
                        const isLoading = loadingPreferences.has(loadingKey);
                        return (
                            <div className="relative flex gap-x-3" key={channelType}>
                                <div className="flex h-6 items-center">
                                    <input
                                        id={`${workflowKey}-${channelType}`}
                                        name={channelType}
                                        type="checkbox"
                                        checked={preferenceSetting}
                                        disabled={loadingPreferences.has(
                                            `workflows-${workflowKey}-channel_types-${channelType}`,
                                        )}
                                        onChange={() =>
                                            updatePreferences(
                                                [
                                                    'workflows',
                                                    workflowKey,
                                                    'channel_types',
                                                    channelType,
                                                ],
                                                !preferenceSetting,
                                            )
                                        }
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                                <label
                                    htmlFor={`${workflowKey}-${channelType}`}
                                    className="text-sm leading-6 font-medium text-gray-900"
                                >
                                    {channelTypeLabels[channelType as ChannelType]}
                                </label>
                                {isLoading && <LoadingSpinner />}
                            </div>
                        );
                    },
                )}
            </div>
        </fieldset>
    );
};

const KnockSettings = () => {
    const [preferences, setPreferences] = useState<PreferenceSet>();
    const [loadingPreferences, setLoadingPreferences] = useState<Set<string>>(new Set());
    const { VITE_KNOCK_PUBLIC_API_KEY, VITE_KNOCK_TENANT_ID } = import.meta.env;

    // Setup our Knock client
    const knockClient = useMemo(() => {
        const knockClient = new Knock(VITE_KNOCK_PUBLIC_API_KEY);
        knockClient.authenticate(id.value.toString());
        return knockClient;
    }, [VITE_KNOCK_PUBLIC_API_KEY, id]);

    const fetchPreferences = async () => {
        const preferences = await knockClient.user.getPreferences({
            tenant: VITE_KNOCK_TENANT_ID,
        });
        setPreferences(preferences);
    };

    // Read the preferences for the current user
    useEffect(() => {
        fetchPreferences();
    }, [knockClient]);

    const updatePreferences = async (path: string[], value: boolean) => {
        if (!preferences) return;

        const pathString = path.join('-');
        setLoadingPreferences((prev) => new Set([...prev, pathString]));

        const preferenceUpdate = _.set({ ...preferences }, path, value);
        const updatedPrefs = await knockClient.user.setPreferences(
            preferenceUpdate as SetPreferencesProperties,
        );

        setPreferences(updatedPrefs);
        setLoadingPreferences((prev) => {
            const newLoading = new Set(prev);
            newLoading.delete(pathString);
            return newLoading;
        });
    };

    if (!preferences) {
        return <div className="text-center py-4">Loading preferences...</div>;
    }

    return (
        <div className="max-w-2xl space-y-8 md:col-span-2">
            <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900">
                    Global Channels
                </legend>
                {Object.entries(preferences.channel_types).map(
                    ([channel, channelSetting]) => (
                        <ChannelOption
                            key={channel}
                            channel={channel}
                            setting={channelSetting}
                            updatePreferences={updatePreferences}
                            loading={loadingPreferences.has(`channel_types-${channel}`)}
                        />
                    ),
                )}
            </fieldset>
            {Object.keys(preferences.workflows).map((workflowKey) => (
                <WorkflowOption
                    key={workflowKey}
                    workflowKey={workflowKey}
                    workflowSetting={preferences.workflows[workflowKey]}
                    updatePreferences={updatePreferences}
                    loadingPreferences={loadingPreferences}
                />
            ))}
        </div>
    );
};

export default KnockSettings;
