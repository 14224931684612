import { PillarUser } from './User';

export type ReviewStatus = 'responseRequired' | 'approved' | 'notStarted';

export enum REVIEW_STATUS {
    responseRequired = 'responseRequired',
    approved = 'approved',
    notStarted = 'notStarted',
}

export const reviewStatusStrings: Record<ReviewStatus, string> = {
    responseRequired: 'Requires Response',
    approved: 'Accepted',
    notStarted: 'Not Started',
};

export interface ReviewMessage {
    id: number;
    message: string;
    createdBy: PillarUser;
    createdAt: string;
    reviewId: number;
}

export interface Review {
    id: number;
    reviewableType: string;
    reviewableId: number;
    createdBy: PillarUser;
    createdAt: string;
    status: ReviewStatus;
    messages: ReviewMessage[];
    reviewType: ReviewType;
}

export interface ApiUser {
    id: number;
    email: string;
    first_name: string | null;
    last_name: string | null;
}

export interface ApiReviewMessage {
    id: number;
    message: string;
    timestamp: string;
    created_by: ApiUser;
    review_id: number;
}

export interface ApiReviewable {
    id: number;
    title: string;
    section: string;
    control_type: string;
    standards: Record<string, string>;
    identifier: string;
    archived: boolean;
    status: string;
    ref_id: string;
}

export enum ReviewType {
    manager = 'manager',
    supervisor = 'supervisor',
}

export interface ApiReview {
    id: number;
    status: string | null;
    timestamp: string;
    messages: ApiReviewMessage[];
    created_by: ApiUser;
    reviewable: Partial<ApiReviewable>;
    review_type: ReviewType;
}

// Mapping between API status and internal status
export const apiToReviewStatusMap: Record<string, ReviewStatus> = {
    response_required: 'responseRequired',
    approved: 'approved',
    not_started: 'notStarted',
};

export const reviewToApiStatusMap: Record<ReviewStatus, string> = {
    responseRequired: 'response_required',
    approved: 'approved',
    notStarted: 'not_started',
};
