//GridResults.tsx
import { CheckIcon } from '@heroicons/react/24/outline';
import { CustomCellRendererProps } from 'ag-grid-react';
import React from 'react';

import { useGetCard, useUpdateCard } from '../services/auditService';
import { selectedAudit } from '../state/audit';
import { Card, ResultDetails } from '../Types/Card';
import { SelectBox } from './ui/Select';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export enum RESULT_STATUS_STRINGS {
    NO_EXCEPTIONS = 'No exceptions noted',
    EXCEPTIONS = 'Exceptions Noted',
    POSITIVE_OBSERVATION = 'Positive Observation',
    PROCESS_IMPROVEMENT = 'Process Improvement',
    MINOR_NON_CONFORMITY = 'Minor Non-conformity',
    MAJOR_NON_CONFORMITY = 'Major Non-conformity',
}

const resultStatusColorMapping = (value: string) => {
    switch (value) {
        case RESULT_STATUS_STRINGS.NO_EXCEPTIONS:
            return { backgroundColor: 'bg-green-500' };
        case RESULT_STATUS_STRINGS.EXCEPTIONS:
            return { backgroundColor: 'bg-red-500' };
        case RESULT_STATUS_STRINGS.POSITIVE_OBSERVATION:
            return { backgroundColor: 'bg-blue-500' };
        case RESULT_STATUS_STRINGS.PROCESS_IMPROVEMENT:
            return { backgroundColor: 'bg-gray-200' };
        case RESULT_STATUS_STRINGS.MINOR_NON_CONFORMITY:
        case RESULT_STATUS_STRINGS.MAJOR_NON_CONFORMITY:
            return { backgroundColor: 'bg-yellow-500' };
        default:
            return { backgroundColor: 'bg-gray-200' };
    }
};

interface GridCellReviewParams extends CustomCellRendererProps {
    prop: string;
}

const resultHasInquiry = (details?: ResultDetails) => {
    if (!details || !details.inquiry) return false;
    return (
        !!details.inquiry?.notes || !!details.inquiry?.tests || !!details.inquiry?.results
    );
};

const resultHasException = (details?: ResultDetails) => {
    if (!details || !details.exceptionDisclosure) return false;
    return (
        !!details.exceptionDisclosure?.notes ||
        !!details.exceptionDisclosure?.tests ||
        !!details.exceptionDisclosure?.results
    );
};

const resultHasBasis = (details?: ResultDetails) => {
    if (!details || !details.basisOfDesign) return false;
    return (
        !!details.basisOfDesign.notes ||
        !!details.basisOfDesign.tests ||
        !!details.basisOfDesign.results
    );
};

const resultHasDetails = (details?: ResultDetails) => {
    if (!details) return false;
    return (
        resultHasInquiry(details) ||
        resultHasException(details) ||
        resultHasBasis(details)
    );
};

function GridCellResult({ data: row }: GridCellReviewParams) {
    const auditId = selectedAudit?.value?.id;
    const cardId = row?.id;
    const resultStatusValues = Object.values(RESULT_STATUS_STRINGS);

    const {
        data: card,
        isError: _e,
        error: _err,
        isLoading: _l,
    } = useGetCard(auditId, cardId);
    const updateCardMutation = useUpdateCard();

    const selected =
        card?.results?.status || RESULT_STATUS_STRINGS.NO_EXCEPTIONS.toString();
    const resultDetails = card?.results?.details?.tests;

    const handleStatusChange = (status: string) => {
        const updatedCard: Partial<Card> = {};
        updatedCard.results = {
            status,
        };
        if (card?.id) {
            updateCardMutation.mutate({
                cardId: card.id,
                card: updatedCard,
            });
        }
    };

    return (
        <div className="flex flex-col h-100">
            <SelectBox
                onChange={handleStatusChange}
                items={resultStatusValues}
                selected={selected}
                getItemLabel={(status) => status}
                getItemIcon={(status) => (
                    <span
                        aria-label={status}
                        className={classNames(
                            resultStatusColorMapping(status)?.backgroundColor ?? '',
                            'inline-block h-2 w-2 flex-shrink-0 rounded-full',
                        )}
                    />
                )}
            ></SelectBox>
            {card?.results?.status !== RESULT_STATUS_STRINGS.NO_EXCEPTIONS && (
                <p className="mt-2">{resultDetails}</p>
            )}
            {resultHasDetails(card?.results?.details) && (
                <div className="p-1.5 text-xs text-gray-800">
                    {resultHasInquiry(card?.results?.details) && (
                        <div className="flex flex-row h-4">
                            <CheckIcon className="pr-1.5"></CheckIcon>Inquiry
                        </div>
                    )}
                    {resultHasException(card?.results?.details) && (
                        <div className="flex flex-row h-4">
                            <CheckIcon className="pr-1.5"></CheckIcon>
                            Exception/Disclosure
                        </div>
                    )}
                    {resultHasBasis(card?.results?.details) && (
                        <div className="flex flex-row h-4">
                            <CheckIcon className="pr-1.5"></CheckIcon>Basis of Design
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default GridCellResult;
