import React from 'react';

import Nav from './Nav';

interface ContainerProps {
    children: React.ReactNode;
}

function Container({ children }: ContainerProps) {
    return (
        <>
            <Nav />
            <main className="ml-24">
                <div className="p-4">
                    <div className="overflow-hidden rounded-lg bg-white shadow">
                        {children}
                    </div>
                </div>
            </main>
        </>
    );
}

export default Container;
