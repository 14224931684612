// IframeContainer.tsx
import React from 'react';

import MicroWrapper from './MicroWrapper';

interface IframeContainerProps {
    src: string;
    title: string;
    appName: string;
}

const IframeContainer: React.FC<IframeContainerProps> = ({ src, title, appName }) => {
    const headerContent = (
        <>
            <div className="px-4 py-5 sm:px-6">{appName}</div>
        </>
    );

    const bodyContent = (
        <iframe src={src} title={title} style={{ width: '100%', height: '100%' }} />
    );

    return <MicroWrapper header={headerContent} body={bodyContent} />;
};

export default IframeContainer;
