import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Portal,
    Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useEffect, useRef, useState } from 'react';

interface SelectBoxProps<T> {
    label?: string;
    items: T[];
    selected: T;
    onChange: (item: T) => void;
    getItemLabel: (item: T) => string;
    getItemIcon?: (item: T) => React.ReactNode;
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export function SelectBox<T>({
    label,
    items,
    selected,
    onChange,
    getItemLabel,
    getItemIcon,
}: SelectBoxProps<T>) {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [buttonPosition, setButtonPosition] = useState<{
        top: number;
        left: number;
        width: number;
        height: number;
    } | null>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const updateButtonPosition = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setButtonPosition({
                top: rect.top + window.scrollY,
                left: rect.left + window.scrollX,
                width: rect.width,
                height: rect.height,
            });
        }
    };

    const handleOpen = (open: boolean) => {
        if (open) {
            updateButtonPosition();
            setIsDropdownOpen(true);
        } else {
            setIsDropdownOpen(false);
        }
    };

    return (
        <Listbox value={selected} onChange={onChange}>
            {({ open }) => {
                useEffect(() => {
                    handleOpen(open);
                }, [open]);

                return (
                    <>
                        {label && (
                            <Label className="block text-sm font-medium leading-6 text-gray-900">
                                {label}
                            </Label>
                        )}

                        <div className="relative mt-2">
                            <ListboxButton
                                ref={buttonRef}
                                className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                                <span className="flex items-center">
                                    {getItemIcon && getItemIcon(selected)}
                                    <span className="ml-3 block truncate">
                                        {getItemLabel(selected)}
                                    </span>
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </span>
                            </ListboxButton>

                            {isDropdownOpen && buttonPosition && (
                                <Portal>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top:
                                                buttonPosition.top +
                                                buttonPosition.height,
                                            left: buttonPosition.left,
                                            width: buttonPosition.width,
                                            zIndex: 10,
                                        }}
                                    >
                                        <Transition
                                            show={open}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                            as={Fragment}
                                        >
                                            <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {items.map((item, index) => (
                                                    <ListboxOption
                                                        key={index}
                                                        className={({ active }) =>
                                                            classNames(
                                                                active
                                                                    ? 'bg-indigo-600 text-white'
                                                                    : '',
                                                                !active
                                                                    ? 'text-gray-900'
                                                                    : '',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                                            )
                                                        }
                                                        value={item}
                                                    >
                                                        {({ selected, active }) => (
                                                            <>
                                                                <div className="flex items-center">
                                                                    {getItemIcon &&
                                                                        getItemIcon(item)}
                                                                    <span
                                                                        className={classNames(
                                                                            selected
                                                                                ? 'font-semibold'
                                                                                : 'font-normal',
                                                                            'ml-3 block truncate',
                                                                        )}
                                                                    >
                                                                        {getItemLabel(
                                                                            item,
                                                                        )}
                                                                    </span>
                                                                </div>

                                                                {selected ? (
                                                                    <span
                                                                        className={classNames(
                                                                            active
                                                                                ? 'text-white'
                                                                                : 'text-indigo-600',
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                        )}
                                                                    >
                                                                        <CheckIcon
                                                                            className="h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </ListboxOption>
                                                ))}
                                            </ListboxOptions>
                                        </Transition>
                                    </div>
                                </Portal>
                            )}
                        </div>
                    </>
                );
            }}
        </Listbox>
    );
}
